<template>
  <div class="field select-input">
    <div class="fieldlabel">
      {{ $t(label) }}
      <span class="unit" v-if="$te(unit)">({{ $t(unit) }})</span>
    </div>
    <div class="control-frame">
      <div class="control">
        <select
          v-model="selected"
          v-bind:name="name"
          v-on:change="onChange">
            <option
              v-for="option in options"
              v-bind:key="option.value"
              v-bind:value="option">
                {{ $t(option.label, option.parse) }}
            </option>
        </select>
        <div class="select-label">{{ $t(selected.label, selected.parse) }}</div>
        <svg-icon name="tke_icon_select"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import Bus from '../../services/EventService'
import InputModel from '../../models/InputModel'
import InputModelCollection from '../../models/InputModelCollection'

export default {
  name: 'select-input',
  props: ['name', 'options', 'label', 'preset', 'unit'],
  data () {
    return {
      selected: {}
    }
  },
  methods: {
    onChange (event) {
      var Input, Collection

      // no calculation is done for input se, @see store.inputValueChanged()
      Input = new InputModel(this.name, this.selected.value)
      this.$store.commit('inputValueChanged', Input)

      // Invoke calculation on the base of adjustments values
      Collection = new InputModelCollection()
      for (var key in this.selected.set) {
        Collection.new(key, this.selected.set[key])
      }
      if (Collection.count > 0) {
        this.$store.commit('setInputValues', Collection)
      }
    },

    /**
     * Event when an input changed from outside, before calculation
     * invoked by $store.inputValueChanged()
     * invoked by $store.setInputValues()
     *
     * @param {InputModelCollection} Collection
     */
    onInputValueChanged (Collection) {
      var Input
      if (Collection.has(this.name)) {
        Input = Collection.get(this.name)
        if (!Input.compare(this.selected.value)) {
          this.setValue(Input.value)
        }
      }
    },

    /**
     * Setter for selected, takes the value as argument
     *
     * @param number value
     */
    setValue (value) {
      var i
      for (i = 0; i < this.options.length; i++) {
        if (this.options[i].value === value) {
          this.selected = this.options[i]
          return
        }
      }
    }
  },
  created () {
    var check, i
    this.setValue(fn.getOption(this.preset, this.options).value)
    Bus.listen('calc/on-input-value-changed', this.onInputValueChanged)
  }
}
</script>

<style lang="sass">
.select-input

  .control-frame
    padding: 2px
    background-color: $white
    border: 1px solid $border

    .control
      position: relative
      display: flex
      background-color: $white
      height: 2rem

      .select-label
        padding: 0 1.1rem 0 .3rem
        line-height: 2rem
        font-size: $size-4
        color: $info
        font-family: $family-mono-light
        cursor: pointer
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

      .tke_icon_select
        position: absolute
        width: 1.1rem
        height: 1.1rem
        right: .3rem
        top: .5rem
        fill: $primary

      select
        position: absolute
        width: 100%
        padding: 0
        height: 1.5rem
        opacity: 0
        filter: alpha(opacity=0)
        visibility: visible
        font-size: .8rem
        border: 0 // Safari

+tablet

  .select-input

    .control-frame

      .control
        height: 1.5rem

        .select-label
          line-height: 1.5rem
          font-size: $size-6

        .tke_icon_select
          top: .2rem

    &.is-big

      .control-frame
        height: 3.2rem
        padding-left: .6rem

        .control

          .select-label
            height: 3.2rem
            line-height: 3.2rem
            color: $info
            text-align: center
            font-size: $size-1
            text-overflow: ellipsis

          .tke_icon_select
            display: none

          select
            height: 2.8rem

+desktop

  .select-input

    &.is-big

      .control-frame

        .control

          .select-label
            font-size: $size-1
</style>
