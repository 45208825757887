<template>
  <section class="section home-page">
    <div class="container">
      <div class="content">
        <div class="columns intro">
           <div class="column is-11">
            <h1>{{ $t("home.title")}}</h1>
            <p>{{ $t("home.intro") }}</p>
           </div>
        </div>
        <div class="columns links">
          <div class="column">
            <router-link :to="{ name: 'cabin' }" class="calc-link">
              <svg-icon name="tke_icon_calc_cabin"></svg-icon>
            </router-link>
            <p >{{ $t("home.cabin.copy") }}</p>
            <p class="is-size-5 font-grey">{{ $t("home.cabin.hint") }}</p>
          </div>
          <div class="column">
            <router-link :to="{ name: 'shaft' }" class="calc-link">
              <svg-icon name="tke_icon_calc_shaft"></svg-icon>
             </router-link>
            <p>{{ $t("home.shaft.copy") }}</p>
            <p class="is-size-5 font-grey">{{ $t("home.shaft.hint") }}</p>
          </div>
        </div>
        <div class="columns footnote">
          <div class="column is-11">
            <p class="is-size-6 font-grey">{{ $t("home.footnote") }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import fn from '../../services/GlobalService'

export default {
  name: 'home-page',
  components: {},
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>

<style lang="sass">
.home-page

  background-color: $white

  .links
    margin-top: 1rem
    margin-bottom: 1rem

    .calc-link
      display: block
      padding: 1rem .5rem
      margin-bottom: 1rem
      background-color: $white-ter
      text-align: center
      line-height: 0

      .svg-icon
        width: 68%
        height: auto
        margin: 2% 0
        fill: $primary

      &:hover
        background-color: $link-hover

        .svg-icon
          fill: $white

    p
      width: 90%
      line-height: 1.3em
      margin-bottom: .8rem

  .footnote

    p
      line-height: 1.3em

  .font-grey
    color: $text-light

+tablet
  .home-page
    .links
      .calc-link
        padding: 1rem 1rem
</style>
