import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'
import _upperFirst from 'lodash/upperFirst'
import _camelCase from 'lodash/camelCase'

// config
import { languages } from './config/global.json'

// services
import fn from './services/GlobalService'
import i18n from './services/LanguageService'
import Store from './services/StoreService'
import Router from './services/RouterService'
import Analytics from './services/GoogleAnalyticsService'

/*
|--------------------------------------------------------------------------
| Register global (base) views
|--------------------------------------------------------------------------
*/

const requireComponent = require.context(
  './components/base',
  false,
  /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = _upperFirst(
    _camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  )
  Vue.component(componentName, componentConfig.default || componentConfig)
})

/*
|--------------------------------------------------------------------------
| Configuration, Pre-Loading
|--------------------------------------------------------------------------
*/
Vue.use(VueAxios, axios)
Vue.use(VueCookie)
Vue.config.productionTip = false

// Language
var locale = VueCookie.get('locale')
if (!locale || !fn.inArray(locale, languages.available)) {
  locale = languages.default
}
var langPromise = Store.dispatch('loadLanguage', locale)

/*
|--------------------------------------------------------------------------
| Mount application
|--------------------------------------------------------------------------
*/
Promise.all([langPromise]).then(() => {
  new Vue({
    el: '#app',
    router: Router,
    store: Store,
    i18n: i18n,
    template: '<router-view></router-view>'
  })
})
