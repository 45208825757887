<template>
  <div class="main-template">
    <navigation-segment></navigation-segment>
    <header-segment></header-segment>
    <router-view></router-view>
    <footer-segment></footer-segment>
    <overlay-segment></overlay-segment>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import Bus from '../../services/EventService'
import HeaderSegment from '../segments/HeaderSegment'
import NavigationSegment from '../segments/NavigationSegment'
import FooterSegment from '../segments/FooterSegment'
import OverlaySegment from '../segments/OverlaySegment'

export default {
  name: 'main-template',
  components: {
    'header-segment': HeaderSegment,
    'navigation-segment': NavigationSegment,
    'footer-segment': FooterSegment,
    'overlay-segment': OverlaySegment
  },
  methods: {
    setLanguage (locale) {
      document.title = this.$t('page.title')
      this.$cookie.set('locale', locale, 30)
    }
  },
  mounted () {
    this.setLanguage(this.$store.state.locale)
    Bus.listen('global/set-language', this.setLanguage)
  }
}
</script>

<style src="../../styles/base.sass" lang="sass"></style>
<style lang="sass">
</style>
