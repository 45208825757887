/*
|--------------------------------------------------------------------------
| Multi purpose butten
|--------------------------------------------------------------------------
|
| Button can have a scope for the label or not for simple icon-button
| css-class can be "is-medium", "is-small" or "is-tiny" for smaller button
| small and tiny buttons don't have a label
| css-class "flip" moves the icon to the right
*/

<template>
  <button class="icon-button" role="button">
    <div class="elements">
      <svg-icon v-bind:name="icon" class="icon"></svg-icon>
      <span class="count" v-if="count > 0">
        {{ count }}
      </span>
      <span class="legend" v-if="$slots.default">
        <slot></slot>
      </span>
    </div>
  </button>
</template>

<script>
import fn from '../../services/GlobalService'

export default {
  name: 'icon-button',
  props: ['icon', 'count']
}
</script>

<style lang="sass">

  .icon-button
    display: inline
    padding: 0
    margin: 0
    border: none
    background: transparent
    border-color: transparent
    cursor: pointer

    .elements
      display: inline-flex
      height: $icon-normal-mobile
      line-height: $icon-normal-mobile
      font-family: $family-sans-serif-medium
      font-size: $size-4
      color: $primary

      .icon
        width: $icon-normal-mobile
        height: $icon-normal-mobile
        fill: $primary

      .count
        display: inline-block
        margin-left: -.8rem
        width: 1rem
        height: 1rem
        background-color: $danger
        border-radius: .5rem
        color: $white
        font-size: .7rem
        line-height: 1rem
        font-family: $family-sans-serif-bold
        z-index: $z-index-content + 1

      .legend
        padding: 0 .2rem

    &.is-big

      .elements
        height: $icon-big-mobile
        padding-left: $icon-big-mobile
        line-height: $icon-big-mobile
        font-size: $size-4

        .icon
          width: $icon-big-mobile
          height: $icon-big-mobile
          margin-left: -$icon-big-mobile

    &.is-medium

      .elements
        height: $icon-medium-mobile
        padding-left: $icon-medium-mobile
        line-height: $icon-medium-mobile
        font-size: $size-5

        .icon
          width: $icon-medium-mobile
          height: $icon-medium-mobile
          margin-left: -$icon-medium-mobile

    &.is-small

      .elements
        height: $icon-small-mobile
        padding-left: $icon-small-mobile
        line-height: $icon-small-mobile

        .icon
          width: $icon-small-mobile
          height: $icon-small-mobile
          margin-left: -$icon-small-mobile

        .legend
          display: none

    &.is-tiny

      .elements
        width: $icon-tiny-mobile
        height: $icon-tiny-mobile
        line-height: $icon-tiny-mobile

        .icon
          width: $icon-tiny-mobile
          height: $icon-tiny-mobile
          margin-left: -$icon-tiny-mobile

        .count,
        .legend
          display: none

    &.is-flip

      .elements
        flex-direction: row-reverse
        padding-left: 0
        padding-right: $icon-normal-mobile

        .icon
          margin-left: 0
          margin-right: -$icon-normal-mobile

        .count
          display: none

      &.is-big

        .elements
          padding-right: $icon-big-mobile

          .icon
            margin-right: -$icon-big-mobile

      &.is-medium

        .elements
          padding-right: $icon-medium-mobile

          .icon
            margin-right: -$icon-medium-mobile

      &.is-small

        .elements

          .icon
            margin-right: $icon-small-mobile

    &.is-white

      .elements

        .icon
          fill: $white

        .legend
          color: $white

    &.is-inactive
      cursor: default

      .elements

        .icon
            fill: $grey-light

        .legend
          color: $grey-light

    &:focus
      outline: none

  +tablet

    .icon-button

      .elements
        height: $icon-normal
        padding-left: $icon-normal
        line-height: $icon-normal
        font-size: $size-5

        .icon
          width: $icon-normal
          height: $icon-normal
          margin-left: -$icon-normal

      &.is-big

        .elements
          height: $icon-big
          padding-left: $icon-big
          line-height: $icon-big
          font-size: $size-5

          .icon
            width: $icon-big
            height: $icon-big
            margin-left: -$icon-big

      &.is-medium

        .elements
          height: $icon-medium
          padding-left: $icon-medium
          line-height: $icon-medium
          font-size: $size-6

          .icon
            width: $icon-medium
            height: $icon-medium
            margin-left: -$icon-medium

      &.is-small

        .elements
          height: $icon-small
          padding-left: $icon-small
          line-height: $icon-small

          .icon
            width: $icon-small
            height: $icon-small
            margin-left: -$icon-small

      &.is-tiny

        .elements
          width: $icon-tiny
          height: $icon-tiny
          line-height: $icon-tiny

          .icon
            width: $icon-tiny
            height: $icon-tiny
            margin-left: -$icon-tiny

      &.is-flip

        .elements
          padding-right: $icon-normal

          .icon
            margin-right: -$icon-normal

        &.is-big

          .elements
            padding-right: $icon-big

            .icon
              margin-right: -$icon-big

        &.is-medium

          .elements
            padding-right: $icon-medium

            .icon
              margin-right: -$icon-medium

        &.is-small

          .elements

            .icon
              margin-right: $icon-small

</style>
