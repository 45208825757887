<template>
  <component :is="inlineSVG" :class="name" class="svg-icon" />
</template>

<script>
import fn from '../../services/GlobalService'

/**
 * use this module for extern SVGs
 * requires vue-svg-loader
 * use loader's build in component with Component = import "@/assets/svg/logo?inline"
 * use background: url('../../assets/svg/logo.svg) for background-svgs
 * @see https://vue-svg-loader.js.org/faq.html#how-to-use-both-inline-and-external-svgs
 */
export default {
  name: 'cs-svg',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    inlineSVG () {
      // forces this fn to update
      var src = '@/assets/svg/' + this.name + '.svg?inline'
      return () => import(
        /* webpackChunkName: "svgs" */
        /* webpackMode: "lazy" */ 
        '@/assets/svg/' + this.name + '.svg?inline'
      )
    }
  }
}
</script>

<style lang="sass">
  .svg-icon
    height: 2.4rem
    width: 2.4rem
    fill-rule: evenodd
    clip-rule: evenodd
    stroke-linejoin: round
    stroke-miterlimit: 1.41421
    fill: $black

    &.is-primary
      fill: $primary

    &.is-white
      fill: $white

    &.is-danger
      fill: $red

    &.is-success
      fill: $green
</style>
