<template>
  <footer class="footer footer-segment">
    <div class="container">
      <div class="columns">
        <div class="column copyright is-size-6">{{ $t("page.copyright", { year: currentYear }) }}</div>
        <div class="column is-half disclaimer is-size-7">{{ $t("page.disclaimer") }}</div>
        <div class="column imprint is-size-6">
          <a v-bind:href="$t('links.imprint')" target="_blank">{{ $t('navigation.imprint') }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import fn from '../../services/GlobalService'

export default {
  name: 'footer-segment',
  computed: {
    currentYear () {
      return (new Date()).getFullYear()
    }
  },
  mounted () {}
}
</script>

<style lang="sass">
.footer-segment
  padding-top: 1rem
  padding-bottom: 1rem
  background-color: $black
  z-index: $z-index-content

  .copyright
    color: $grey-light
    font-family: $family-mono-light
    font-size: $size-6

  .disclaimer
    text-align: left
    color: $grey-light

  .imprint
    text-align: left
    font-family: $family-mono
    a
      color: $white
      &:hover
        color: $white

  +tablet
    .disclaimer
      text-align: center

    .imprint
      text-align: right
</style>
